import { faStepBackward, faStepForward } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { IEventHandlerOptions } from 'students/views/shared/bundles/audio/classes';
import { useAudioPlayer } from 'students/views/shared/bundles/audio/hooks';
import styled from 'styled-components';
import { useBreakPoint } from '../../../hooks';
import { customMediaQuery } from '../../../styled';
import { timePrettyPrintHelper } from '../Helpers';
import NavButton from './NavButton';
import PlayButton from './PlayButton';
import Slider from './Slider';
import SpeedDropdown from './SpeedDropdown';
import VolumeTooltip from './VolumeTooltip';

interface IProps {
  url: string;
  className?: string;
  onPlay?: () => void;
  onEnded?: () => void;
  onPause?: () => void;
  progressInterval?: number;
  showNavBtns?: boolean;
  size?: 'small' | 'big';
}

export interface IAudioPlayerRef {
  handlePause: () => void;
}

const AudioPlayer = React.forwardRef<IAudioPlayerRef, IProps>(
  (
    {
      url,
      className,
      onPause,
      onPlay,
      onEnded,
      progressInterval = 100,
      size = 'big',
      showNavBtns = true
    },
    forwardedRef
  ) => {
    const [playing, setPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [played, setPlayed] = useState(0);
    const isMobile = useBreakPoint('sm', true);

    useEffect(() => {
      setPlayed(progress * duration);
    }, [duration, progress]);

    const handleProgress = useCallback((progress: number) => {
      setProgress(progress / 100);
    }, []);

    const handlePlay = useCallback(() => {
      setPlaying(true);
      onPlay && onPlay();
    }, [onPlay]);

    const handlePause = useCallback((): void => {
      setPlaying(false);
      onPause && onPause();
    }, [onPause]);

    const handleLoad = useCallback((options: IEventHandlerOptions) => {
      setDuration(options.player.engine.duration());
    }, []);

    const handleEnd = useCallback(() => {
      onEnded && onEnded();
      setPlaying(false);
    }, [onEnded]);

    const { seek, togglePlayPause, pause, getVolume, setVolume, getSpeed, setSpeed } =
      useAudioPlayer({
        src: url,
        preload: true,
        progressInterval,
        onProgress: handleProgress,
        onPlay: handlePlay,
        onPause: handlePause,
        onLoad: handleLoad,
        onEnd: handleEnd
      });

    useImperativeHandle(
      forwardedRef,
      () => ({
        handlePause: pause
      }),
      [pause]
    );

    const handleTogglePlay = useCallback(() => {
      togglePlayPause();
    }, [togglePlayPause]);

    const handleSeekChange = (value: number): void => {
      seek(value * duration);
      setProgress(value);
    };

    const isSmallSize = size === 'small';

    return (
      <SWrapper className={cn(className, { compact: isSmallSize })}>
        <SProgress className={cn({ compact: isSmallSize })}>
          <SPlayed>
            {timePrettyPrintHelper(played)}
            {!isMobile ? ` / ${timePrettyPrintHelper(duration)}` : null}
          </SPlayed>

          <SSlider played={progress} onSeekChange={handleSeekChange} />

          <VolumeTooltip volume={getVolume()} onVolumeChange={setVolume} />

          <SpeedDropdown currentSpeed={getSpeed()} onSpeedChange={setSpeed} />
        </SProgress>

        <SControls className={cn({ compact: isSmallSize })}>
          {showNavBtns ? <NavButton disabled icon={faStepBackward} /> : null}

          <PlayButton
            size={isSmallSize ? 'sm' : 'md'}
            isPlaying={playing}
            onClick={handleTogglePlay}
          />

          {showNavBtns ? <NavButton disabled icon={faStepForward} /> : null}
        </SControls>
      </SWrapper>
    );
  }
);

const SWrapper = styled.div`
  width: 100%;

  &.compact {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`;

const SProgress = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &.compact {
    width: 100%;
  }
`;

const SSlider = styled(Slider)`
  align-self: center;
  margin-inline-end: 8px;
`;

const SPlayed = styled.div`
  align-self: center;
  font-size: 0.9rem;
  margin-inline-end: 4px;
  width: 3rem;

  ${customMediaQuery('tablet')} {
    width: 8rem;
  }
`;

const SControls = styled.div`
  margin-block-start: 19px;
  display: flex;
  justify-content: center;

  &.compact {
    margin-block-start: 0;

    button {
      --btnWidth: 70px;
      --btnHeight: 70px;
    }
  }
`;

export default AudioPlayer;
